import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { formatFrontmatterDate } from "../../util"
// import PubImage from "../PubImage"
import classNames from "classnames/bind"
import styles from "./styles.module.scss"
import Link from "../IntlLink"
import anime from 'animejs/lib/anime.es.js';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Slider({ lang, delay=5000 }) {

  const cx = classNames.bind(styles);
	
  const data = useStaticQuery(
    graphql`
      query {
        announcements: file(sourceInstanceName: {eq: "images"}, base: {eq: "announcements.jpg"}) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1200, height: 360)
          }
        }
        featuredTop: allMdx(
          filter: {frontmatter: {featuredTop: {eq: true}}}
          sort: {order: DESC, fields: frontmatter___date}
          limit: 1
        ) {
          nodes {
            fields {
              collection
              originalPath
            }
            frontmatter {
              id
              title
              series
              date
              endDate
              location
              authors
              thumbnail {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1200, height: 360)
                }
              }
              cover {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                  # gatsbyImageData(layout: CONSTRAINED, width: 1200, height: 360)
                }
              }
            }
            authors {
              frontmatter {
                memberid
                title
                exclude
              }
              fields {
                originalPath
                lang
              }
            }
          }
        }
      }
    `
  )

	const largeScreen = useMediaQuery('(min-width:700px)');

  const refBkg = React.useRef(null)
  const refTabsContainer = React.useRef(null)
  const refBlob = React.useRef(null)
  const [focus, setFocus] = React.useState(-1)
  const [myInt, setMyInt] = React.useState(null)
  const myIntRef = React.useRef()

  function initInterval() {
    if (myInt) {
      clearInterval(myInt)
    }
    setMyInt(setInterval(rotate, delay))
  }

  // need to store interval ID in ref, otherwise can't clear it
  React.useEffect(() => {
    myIntRef.current = myInt
  }, [myInt])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    setTimeout(() => {
      setFocus(0)
      initInterval()
    }, 500)
    return () => {
      window.removeEventListener('resize', handleResize)
      clearInterval(myIntRef.current)
    }
  }, [])

  function rotate() {
    setFocus(focus => (focus + 1) % numTabs)
  }

  function getNewBlobLocation(tab, cssScale = 1) {
    const scale = 2
    return [tab.left + tab.width/2 - refTabsContainer.current.getBoundingClientRect().left, tab.width/cssScale * scale]
  }

  // update focus
  React.useEffect(() => {
    for (let i = 0; i < numTabs; i ++) {
      if (i !== focus) {
        refBkg.current.children[i].classList.remove(cx('focused'))
        refTabsContainer.current.children[i].classList.remove(cx('focused'))
        refTabsContainer.current.children[i].id = ""
      }
      else {
        refBkg.current.children[i].classList.add(cx('focused'))
        refTabsContainer.current.children[i].classList.add(cx('focused'))
        refTabsContainer.current.children[i].id = "focusedTab"
        const [newLeft, newWidth] = getNewBlobLocation(refTabsContainer.current.children[i].getBoundingClientRect())
        anime({
          targets: `.${cx("blob")}`,
          translateX: newLeft,
          scaleX: newWidth / 100,
          easing: 'easeOutElastic',
          duration: 1000,
        })
        if (focus >= 0)
          refBlob.current.classList.remove(cx('hidden'))
      }
    }
  }, [focus])

  function handleResize() {
    const cssScale = 1.4;
    const [newLeft, newWidth] = getNewBlobLocation(window.document.getElementById("focusedTab").getBoundingClientRect(), cssScale)
    refBlob.current.style.setProperty('transform', `translateX(${newLeft}px) scaleX(${newWidth / 100})`)
  }

  const tabs = [
    {
      title: "หนี้ครัวเรือนไทย",
      bkg:
        <a href="https://projects.pier.or.th/household-debt" target="_blank" rel="nofollow noopener noreferrer">
          <StaticImage
            src="../../../content/images/hhd-scrollytelling.png"
            alt={"หนี้ครัวเรือนไทย วิกฤตแค่ไหน ทำไมถึงไม่ควรมองข้าม"}
            layout="constrained"
            width={1200}
            height={360}
          />
        </a>
    },
    {
      title: "TiDE",
      bkg:
        <a href="https://tide.pier.or.th" target="_blank" rel="nofollow noopener noreferrer">
          <StaticImage
            src="../../../content/images/tide.png"
            alt={"TiDE"}
            layout="constrained"
            width={1200}
            height={360}
          />
        </a>
    },
    // {
    //   title: "Call for Papers",
    //   bkg:
    //     data.featuredTop.nodes.length > 0
    //     ? <Link to={data.featuredTop.nodes[0].fields.originalPath} lang={lang}>
    //         <StaticImage
    //           src="../../../content/images/placeholder/announcements.jpg"
    //           alt={"Featured Announcement"}
    //           layout="constrained"
    //           width={1200}
    //           height={360}
    //         /> 
    //         {/* <GatsbyImage image={data.announcements.childImageSharp.gatsbyImageData} alt={"Featured Announcement"} /> */}
    //         {/* <GatsbyImage image={data.featuredTop.nodes[0].frontmatter[largeScreen ? "cover" : "thumbnail"].childImageSharp.gatsbyImageData} alt={"Featured aBRIDGEd"} /> */}
    //       </Link>
    //     : <div />,
    //   float:
    //     data.featuredTop.nodes.length > 0
    //     ? <div className={cx("floatBox", "bottom", "right")} lang={lang}>
    //         <div className={cx("title")}>Call for Papers: PIER Research Workshop 2024</div>
    //         <div className={cx("details")}>หมดเขต 19 เมษายน 2567</div>
    //       </div>
    //     : <div />
    // },
    // {
    //   title: "Symposium 2023",
    //   bkg:
    //     data.featuredTop.nodes.length > 0
    //     ? <Link to={data.featuredTop.nodes[0].fields.originalPath}>
    //         <GatsbyImage image={data.featuredTop.nodes[0].frontmatter[largeScreen ? "cover" : "thumbnail"].childImageSharp.gatsbyImageData} alt={"Featured aBRIDGEd"} />
    //       </Link>
    //     : <div />,
    //   float:
    //     data.featuredTop.nodes.length > 0
    //     ? (largeScreen ? "" :
    //       <div className={cx("floatBox")}>
    //         <div className={cx("title")}>BOT Symposium 2023:<br />"คน" The Economics of Well-Being</div>
    //         {/* <div className={cx("title")}>{data.featuredTop.nodes[0].frontmatter.title}</div> */}
    //         <div className={cx("details")}>{formatFrontmatterDate(data.featuredTop.nodes[0].frontmatter, lang)} · {data.featuredTop.nodes[0].frontmatter.location}</div>
    //       </div>)
    //     : <div />
    // },
  ]

  // console.log(tabs)
  const numTabs = tabs.length + 1

  function handleTabClick(i) {
    initInterval()
    setFocus(i)
  }

  return(
    <div className={cx("hero", "noprint")}
      // onMouseEnter={() => {
      //   console.log('enter')
      //   clearInterval(myInt)
      // }}
      // onMouseLeave={() => {
      //   console.log('leave')
      //   initInterval()
      // }}
    >
      <div className={cx("main")} ref={refBkg}>
        <div className={cx("defaultBkg")}>
          <Link to="/abridged/" lang={lang}>
            <StaticImage
              src="../../../content/images/main-slider.jpg"
              alt={lang === "th" ? "สถาบันวิจัยเศรษฐกิจป๋วย อึ๊งภากรณ์" : "Puey Ungphakorn Institute for Economic Research"}
              layout="constrained"
              width={1200}
              height={360}
            />
          </Link>
          <div className={cx("tabPad")} />
        </div>
        {tabs.map((tab, i) =>
          <div className={cx("bkg")} key={`background${i}`}>
            {tab.bkg}
            {tab.float}
          </div>
        )}
        <div className={cx("tabs")}>
          <div className={cx("tabsBox", "tabsBackground")} id="searchbar" />
          <div className={cx("blobContainer")}>
            <div className={cx("blob", "hidden")} ref={refBlob}>
              <svg height="100%" width="100%" viewBox="0 0 100 30" preserveAspectRatio="none">
                <path d="M0 15 c 10 0 20 -8 50 -8 S 90 15 100 15 V 30 h -100 z" />
              </svg>
            </div>
          </div>
          <div className={cx("tabsBox", "tabsContainer")} ref={refTabsContainer}>
            <div className={cx("tab")} onClick={() => handleTabClick(0)}>PIER</div>
            {tabs.map((tab, i) =>
              <div className={cx("tab")} onClick={() => handleTabClick(i + 1)} key={`tab${i}`}>
                <div className={cx("text")}>{tab.title}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className={cx("tabsMobile", "flex-padding")}>
        <div>
          {tabs.map((tab, i) =>
            <div className={cx("tabMobile")}>
              {tab.bkg}
              <div className={cx("tabTitle")}>{tab.title}</div>
              {tab.float && <div className={cx("tabFloat")}>{tab.float}</div>}
            </div>
          )}
        </div>
      </div> */}
    </div>
  )

}
