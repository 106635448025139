import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Link from "../components/IntlLink"
import Layout from "../components/Layout"
import Slider from "../components/Slider"
import PubImage from "../components/PubImage"
import { formatFrontmatterDate, FormattedAuthor, getAuthors } from "../util"
import TabbedSlider from "../components/TabbedSlider"
import { withLocation } from "../util"
import PageMoved from "../components/PageMoved"
import useMediaQuery from '@mui/material/useMediaQuery';
import StructuredData from "../components/StructuredData"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

function Home({ pageContext: { language }, location, params, data: { featuredEconomist, featuredEvent, latestBlog, site, latestDP, latestAbridged, latestEvents } }) {
	
	const cx = classNames.bind(styles);
	const finalFeaturedEconomist = featuredEconomist.nodes.filter(node => node.fields.lang === language)[0]

	const largeScreen = useMediaQuery('(min-width:700px)');
	const [newLoc, setNewLoc] = React.useState('')

	const loopList = ['p', 'conferences', 'research_workshop', 'seminar', 'abridged']
	for (let i = 0; i < loopList.length; i ++) {
		const param = loopList[i]
		if (param in params) {
			fetch(`redirects-${param}.json`)
				.then(res => res.json())
				.then(res => setNewLoc(res[params[param]]))
		}
	}

	function foo(node) {
		return node.authors && getAuthors(node.frontmatter.authors, node.authors, language)
	}

	function OtherStuff({ latestNodes, className }) {

		return(
			<div className={(className ? className + " " : "") + cx("moreItems")}>
				{latestNodes.map((node, index) =>
					<Link to={node.fields.originalPath} className="nohover nolinkcolor" key={`other${index}`}>
						<div className={cx("moreItem")}>
							<div className={cx("title")}>{node.frontmatter.title}</div>
							<div className={cx("details")}>{formatFrontmatterDate(node.frontmatter, language)}</div>
						</div>
					</Link>
				)}
			</div>
		)

	}

	if (newLoc)
		return <PageMoved newLocation={newLoc} origin={location.origin} lang={language} />

  return(
  	<Layout
			lang={language}
			availableLanguages={["th", "en"]}
			indexThis={false}
			noBanner={true}
			pathname={"/"}
			// customTitle={true}
			shareThis={false}
			title={language === "th" ? "สถาบันวิจัยเศรษฐกิจป๋วย อึ๊งภากรณ์" : "Puey Ungphakorn Institute for Economic Research"}
			top={<Slider lang={language} />}
		>
			<StructuredData>
				{[
					{
						"@context": "https://schema.org",
						"@type": "Organization",
						address: {
							"@type": "PostalAddress",
							addressCountry: "TH",
							addressLocality: "Bangkok, Thailand",
							postalCode: "10200",
							streetAddress: "273 Samsen Rd",
						},
						email: "pier@bot.or.th",
						name: "Puey Ungphakorn Institute for Economic Research",
						url: site.siteMetadata.siteUrl,
						logo: `${site.siteMetadata.siteUrl}/pier-square-white.png`,
					},
					{
						"@context": "https://schema.org",
						"@type": "WebSite",
						url: site.siteMetadata.siteUrl,
						potentialAction: {
							"@type": "SearchAction",
							target: {
								"@type": "EntryPoint",
								urlTemplate: `${site.siteMetadata.siteUrl}/search?q={search_term_string}`,
							},
							"query-input": "required name=search_term_string"
						}
					},			
				]}
			</StructuredData>

			<div className={cx("flexContainer", "topFlex")}>

				{// Discussion Paper
				}
				<div className={cx("topItem")}>
					<div className={cx("cellTitle")}>
						<Link to={"/dp/"} className="nolinkcolor nohover">Discussion Paper</Link>
					</div>
					<div className={cx("topPaper")}>
						<StaticImage
							src="../../content/images/paper.jpg"
							alt="Discussion Paper"
							layout="fullWidth"
						/>
						<div className={cx("latestDetailBackground")}>
							<div className={cx("dogear")}>
								<svg version="1.1" viewBox="0 40.7 96.9 96.9" width="100%" height="100%">
									<path className={cx("fillPaper")} d="M 0 40.7 V 137.6 C 3.0945 137.594 8.8834 137.254 15.4375 135.312 C 30.4869 130.855 42.0585 126.67 63.5 105.156 C 84.9415 83.6421 88.4034 75.8904 94.0938 61.0312 C 95.9783 56.1101 96.9062 50.559 96.9 44.7 V 40.7 Z"/>
									<path className={cx("turn")} d="M 15.4305 135.327 C 30.4799 130.869 49.6 117.921 49.6 81 C 73.9391 85.1316 88.4028 75.8891 94.0931 61.03 C 88.4028 75.8891 84.9361 83.6555 63.4946 105.1697 C 42.0531 126.6838 30.4799 130.869 15.4305 135.327 Z"/>
								</svg>
							</div>
						</div>
						<div className={cx("latestDetail")}>
							<Link to={latestDP.nodes[0].fields.originalPath} className={cx("nohover", "nolinkcolor", "top")}>
								{latestDP.nodes[0].frontmatter.title}
							</Link>
							<div className={cx("bottom", "details")}>
								<div className={cx("bottomDate")}>
									{formatFrontmatterDate(latestDP.nodes[0].frontmatter, language)}
								</div>
								<div className={cx("authors")}>
									{foo(latestDP.nodes[0]).map((a, i) =>
										<React.Fragment key={`author${i}`}>
											<FormattedAuthor author={a} /><br />
										</React.Fragment>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className={cx("moreContainer")}>
						<OtherStuff className={cx("dpMore")} title="Discussion Paper" link="/dp/" latestNodes={latestDP.nodes.slice(1)} />
					</div>
					<div className={cx("details", "seeMore")}>
						<Link to={"/dp/"}>
							{`[ ${language === "th" ? "ดูเพิ่มเติม" : "See more"} ]`}
						</Link>
					</div>
				</div>

				{// aBRIDGEd
				}
				<div className={cx("topItem")}>
					<div className={cx("cellTitle", "noTransform")}>
						<Link to={"/abridged/"} className="nolinkcolor nohover">aBRIDGEd</Link>
					</div>
					<div className={cx("sliderContainer")}>
						<TabbedSlider
							backgrounds={latestAbridged.nodes.map(node => <GatsbyImage image={node.frontmatter.thumbnail?.childImageSharp.gatsbyImageData || node.frontmatter.cover.childImageSharp.gatsbyImageData} alt={node.frontmatter.title} />)}
							tabs={latestAbridged.nodes.map(node => node.frontmatter.title)}
							links={latestAbridged.nodes.map(node => node.fields.originalPath)}
						/>
					</div>
					<div className={cx("details", "seeMore")}>
						<Link to={"/abridged/"}>
							{`[ ${language === "th" ? "ดูเพิ่มเติม" : "See more"} ]`}
						</Link>
					</div>
				</div>
			</div>

			<hr className={cx("midplus")} />

			<div className={cx("bottomGrid")}>

				{//Featured Economist
				}
				<div className={cx("item", "itemEconomist")} id="featured-economist">
					<div className={cx("cellTitle", "titleTop")}>Featured Economist</div>
					{
						finalFeaturedEconomist &&
						<div className={cx("economistGrid")}>
							<Link to={finalFeaturedEconomist.fields.originalPath}>
								<GatsbyImage image={finalFeaturedEconomist.frontmatter.image.childImageSharp.gatsbyImageData} alt={finalFeaturedEconomist.frontmatter.title} />
							</Link>
							<div>
								<div className={cx("cellTitle", "titleBottom")}>Featured Economist</div>
								<div className={cx("top", "nohover", "nolinkcolor")}>
									<Link to={finalFeaturedEconomist.fields.originalPath} className={cx("nohover", "nolinkcolor")}>
										{finalFeaturedEconomist.frontmatter.title}
									</Link>
								</div>
								<div className={cx("body")}>
									<p>{finalFeaturedEconomist.excerpt}</p>
									{finalFeaturedEconomist.publications[0] &&
                    <p>
                      {language === "th" ? "ผลงานล่าสุด : " : "Latest article: "}
                      <Link to={finalFeaturedEconomist.publications[0].fields.originalPath}>
                        {finalFeaturedEconomist.publications[0].frontmatter.title}
                      </Link>
                    </p>
                  }
								</div>
							</div>
						</div>
					}
				</div>

				{// Featured Event
				}
				{featuredEvent.nodes.length > 0
				? <Link to={featuredEvent.nodes[0].fields.originalPath}>
						<div className={cx("item", "itemFeaturedEvent", "zoom-container")}>
							<PubImage f={featuredEvent.nodes[0].frontmatter} collection={featuredEvent.nodes[0].fields.collection} customStyle={largeScreen ? "square" : "cover"} className="noprint" />
							<div className={cx("cellTitle")}>{featuredEvent.nodes[0].frontmatter.title}</div>
							<div className={cx("details")}>
								{formatFrontmatterDate(featuredEvent.nodes[0].frontmatter, language)}<br />
								{featuredEvent.nodes[0].frontmatter.location}
							</div>
						</div>
					</Link>
				: <div />
				}
				
				{// Latest Events
				}
				<div className={cx("item", "itemLatestEvents")}>
					<div className={cx("cellTitle")}>Latest Events</div>
					<OtherStuff title="aBRIDGEd" link="/abridged/" latestNodes={latestEvents.nodes} />
				</div>

				{// PIER Blog
				}
				<div className={cx("item", "itemStats")}>
					<div className={cx("pic", {hoverable: latestBlog.nodes[0].frontmatter.series === "PIER Statistics"})}>
						<Link to={latestBlog.nodes[0].frontmatter.series === "PIER Statistics" ? latestBlog.nodes[0].fields.originalPath : ""}>
							<PubImage f={latestBlog.nodes[0].frontmatter} collection="blog" customStyle="fullwidth" objectFit="contain" objectPosition="right 50%" />
						</Link>
					</div>
					<div className={cx("text")}>
						<div className={cx("cellTitle")}>
							<Link to="/blog/">PIER Blog</Link>
						</div>
						<div className={cx("top", "nohover", "nolinkcolor")}>
							<Link to={latestBlog.nodes[0].fields.originalPath} className={cx("nohover", "nolinkcolor")}>
								{latestBlog.nodes[0].frontmatter.title}
							</Link>
						</div>
						<div className={cx("body")}>
							{latestBlog.nodes[0].frontmatter.excerptOverride || latestBlog.nodes[0].excerpt}
						</div>
					</div>
				</div>

			</div>

  	</Layout>
  )

}

export default withLocation(Home)

export const pageQuery = graphql`
	query HomeQuery {
		site {
			siteMetadata {
				siteUrl
			}
		}
		latestDP: allMdx(
			filter: {fields: {collection: {eq: "dp"}}}
			sort: {fields: [frontmatter___date, frontmatter___id], order: [DESC, DESC]}
			limit: 3
		) {
			nodes {
				fields {
					originalPath
				}
				frontmatter {
					id
					title
					date
					authors
				}
				authors {
					frontmatter {
						memberid
						title
						exclude
					}
					fields {
						originalPath
						lang
					}
				}
			}
		}
		latestAbridged: allMdx(
			filter: {fields: {collection: {eq: "abridged"}}}
			sort: {fields: [frontmatter___date, frontmatter___id], order: [DESC, DESC]}
			limit: 3
		) {
			nodes {
				fields {
					originalPath
				}
				frontmatter {
					title
					date
					thumbnail {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 600, height: 600)
						}
					}
					cover {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 600, height: 600)
						}
					}
				}
			}
		}
		featuredEconomist: allMdx(
			filter: {fields: {collection: {eq: "members"}}, frontmatter: {featuredEconomist: {eq: true}}}
		) {
			nodes {
				publications {
					fields {
						originalPath
					}
					frontmatter {
						title
					}
				}
				excerpt(pruneLength: 300, truncate: true)
				fields {
					originalPath
					lang
				}
				frontmatter {
					title
					image {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
		}
		featuredEvent: allMdx(
			filter: {frontmatter: {featuredEvent: {eq: true}}, fields: {collection: {in: ["conferences", "workshops", "forums", "seminars", "exchanges"]}}}
			sort: {fields: [frontmatter___date, frontmatter___startTime], order: [DESC, DESC]}
			limit: 1
		) {
			nodes {
				fields {
					collection
					originalPath
				}
				frontmatter {
					id
					title
					series
					date
					endDate
					location
					authors
					discussants
					thumbnail {
						childImageSharp {
							cover: gatsbyImageData(layout: CONSTRAINED, width: 650, height: 315)
							square: gatsbyImageData(layout: CONSTRAINED, width: 650, height: 650)
						}
					}
					cover {
						childImageSharp {
							cover: gatsbyImageData(layout: CONSTRAINED, width: 650, height: 315)
							square: gatsbyImageData(layout: CONSTRAINED, width: 650, height: 650)
						}
					}
				}
				authors {
					frontmatter {
						memberid
						title
						exclude
					}
					fields {
						originalPath
						lang
					}
				}
			}
		}
		latestEvents: allMdx(
			filter: {fields: {collection: {in: ["conferences", "workshops", "forums", "seminars", "exchanges", "briefs"]}, isFuture: {eq: false}}, frontmatter: {hidden: {ne: true}}}
			sort: {fields: [frontmatter___date, frontmatter___startTime], order: [DESC, DESC]}
			limit: 3
		) {
			nodes {
				fields {
					originalPath
				}
				frontmatter {
					title
					date
					endDate
					authors
					discussants
					cover {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 500)
						}
					}
				}
				authors {
					frontmatter {
						memberid
						title
						exclude
					}
					fields {
						originalPath
						lang
					}
				}
				discussants {
					frontmatter {
						memberid
						title
						exclude
					}
					fields {
						originalPath
						lang
					}
				}
			}
		}
		latestBlog: allMdx(
			filter: {fields: {collection: {eq: "blog"}}}
			sort: {fields: [frontmatter___date, frontmatter___id], order: [DESC, DESC]}
			limit: 1
		) {
			nodes {
				frontmatter {
					title
					cover {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED)
						}
					}
					excerptOverride
					series
				}
				fields {
					originalPath
				}
				excerpt(pruneLength: 300, truncate: true)
			}
		}
	}
`