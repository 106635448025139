import React from "react"
import Layout from "../../components/Layout"
import Link, { navigate } from "../../components/IntlLink"

export default function PageMoved({ newLocation, origin, lang }) {

  React.useEffect(() => {
		setTimeout(() => navigate(newLocation), 5000)
  }, [])

  function NewURL() {
    // console.log(origin, lang, newLocation)
    return <Link to={newLocation}>{origin + (lang === "th" ? "" : "/en") + newLocation}</Link>
  }

  return(
  	<Layout
			lang={lang}
			title={"Page Moved"}
			pathname={"/"}
			indexThis={false}
			shareThis={false}
		>
    {
      lang === "th"
      ? <React.Fragment>
          <p>
            ดูเหมือนว่าคุณกำลังอยากไปที่หน้าเว็บเดิม ขณะนี้ หน้าที่คุณต้องการ ถูกย้ายไปอยู่ที่ <NewURL /> แล้ว
          </p>
          <p>
            เราจะพาคุณจะไปหน้านั้นโดยอัตโนมัติใน 5 วินาที
          </p>
        </React.Fragment>
      : <React.Fragment>
          <p>
            It seems like you have our old URL. The page has been moved to <NewURL />.
          </p>
          <p>
            You'll be redirected there in 5 seconds.
          </p>
        </React.Fragment>
    }
      
  	</Layout>
  )

}