import React from "react"
import Link from "../IntlLink"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

export default function TabbedSlider({ backgrounds, tabs, links, delay=5000 }) {

	const cx = classNames.bind(styles);

  const numTabs = backgrounds.length

  const refBkg = React.useRef(null)
  const refTab = React.useRef(null)
  const [focus, setFocus] = React.useState(0)
  const [myInt, setMyInt] = React.useState(null)
  const myIntRef = React.useRef()

  function initInterval() {
    if (myInt) {
      clearInterval(myInt)
    }
    setMyInt(setInterval(rotate, delay))
  }

  // need to store interval ID in ref, otherwise can't clear it
  React.useEffect(() => {
    myIntRef.current = myInt
  }, [myInt])

  React.useEffect(() => {
    initInterval()
    return () => {
      clearInterval(myIntRef.current)
    }
  }, [])

  function rotate() {
    setFocus(focus => (focus + 1) % numTabs)
  }

  React.useEffect(() => {
    for (let i = 0; i < numTabs; i ++) {
      if (i !== focus) {
        refBkg.current.children[i].classList.remove(cx('focused'))
        refTab.current.children[i].classList.remove(cx('focused'))
      }
      else {
        refBkg.current.children[i].classList.add(cx('focused'))
        refTab.current.children[i].classList.add(cx('focused'))
      }
    }
  }, [focus])

  return(
    <div
      className={cx("main")}
      // onMouseEnter={() => clearInterval(myInt)}
      // onMouseLeave={() => initInterval()}
    >
      <div ref={refBkg}>
        {backgrounds.map((b, i) =>
          <div className={cx("bkg")} key={`background${i}`}>{b}</div>
        )}
      </div>
      <div className={cx("tabsContainer")} ref={refTab}>
        {tabs.map((tab, i) =>
          <Link to={links[i]} onMouseEnter={() => setFocus(i)} key={`link${i}`}>
            <div className={cx("tab")}>{tab}</div>
          </Link>
        )}
      </div>
    </div>
  )

}